module API {

    export var url = window.location.href;
    url = url.match(/^.*\/\/[^\/]+/)[0];
    url = url.replace(/^(https?:\/\/)(www.)?/, "$1api.") + "/";

    //url = url.replace(/^(https?:\/\/)/, "$1api.") + "/";
    //var url = "http://api.trueview.local/"
    //export var url = "http://api.truview.com/"




    export function Get(endpoint: string): WinJS.Promise<XMLHttpRequest> {
        //WinJS.xhr({
        //    type: "get",
        //    url: String.format("{0}{1}", url, endpoint),
        //    customRequestInitializer: function (xhr: XMLHttpRequest) {
        //        xhr.withCredentials = true;
        //    }
        //})
        //    .then((value) => console.log(value),
        //    (error) => console.log(error));

        return WinJS.xhr({
            type: "get",
            headers: { "If-Modified-Since": "Mon, 27 Mar 1972 00:00:00 GMT" },
            url: String.format("{0}{1}", url, endpoint),
            customRequestInitializer: function (xhr: XMLHttpRequest) {
                xhr.withCredentials = true;
            }
        })
    };


    /// Sending properties that are not stated in the odata parameters will result in
    /// a null value for everything on the server side.
    /// Also sending a "," for the last item in the json will also result in a null value
    export function Post(endpoint: string, data: any): WinJS.Promise<XMLHttpRequest> {
        return WinJS.xhr({
            type: "post",
            url: String.format("{0}{1}", url, endpoint),
            headers: { "Content-Type": "application/json" },
            customRequestInitializer: function (xhr: XMLHttpRequest) {
                xhr.withCredentials = true;
            },
            data: JSON.stringify(data, null, 4)
        });
    };

    export function PostMulti(endpoint: string, data: FormData): WinJS.Promise<XMLHttpRequest> {
        return WinJS.xhr({
            type: "post",
            url: String.format("{0}{1}", url, endpoint),
            //headers: { "Content-Type": "multipart/form-data" },
            customRequestInitializer: function (xhr: XMLHttpRequest) {
                xhr.withCredentials = true;
            },
            data: data
        });
    };

    export function Patch(endpoint: string, data: any): WinJS.Promise<XMLHttpRequest> {
        return WinJS.xhr({
            type: "patch",
            url: String.format("{0}{1}", url, endpoint),
            headers: { "Content-Type": "application/json" },
            customRequestInitializer: function (xhr: XMLHttpRequest) {
                xhr.withCredentials = true;
            },
            data: JSON.stringify(data, null, 4)
        });
    };

    export function Delete(endpoint: string): WinJS.Promise<XMLHttpRequest> {
        return WinJS.xhr({
            type: "delete",
            url: String.format("{0}{1}", url, endpoint),
            customRequestInitializer: function (xhr: XMLHttpRequest) {
                xhr.withCredentials = true;
            }
        });
    };




}


